<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <div class="container d-flex justify-content-between align-items-center">
        <div class="logo">
          <img src="@/assets/images/logo/logo_home.png">
        </div>

        <nav
          id="navbar"
          class="navbar"
        >
          <ul>
            <li><a
              class="active "
            >TRANG CHỦ</a></li>
            <li><a>DOANH NGHIỆP</a></li>
            <li><a>VĂN BẢN PHÁP LÝ</a></li>
            <li><a>TIN TỨC</a></li>
            <router-link
              v-if="!tokenCheck"
              to="/login"
            >
              <i class="bi bi-box-arrow-in-right mr-1"></i>Đăng nhập
            </router-link>
            <!-- <router-link
              to="/login"
            >

            </router-link> -->
            <li
              v-else
              class="dropdown"
            >
              <img
                src="@/assets/images/logo/avt_home.png"
                class="avt-admin"
              >
              <ul>
                <li><a href="/dashboard/statics">Quản trị viên</a></li>
                <li><a href="/setting/list">Cài đặt</a></li>
                <li @click="logout">
                  <a href="#">Đăng xuất</a>
                </li>
              </ul>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <router-view></router-view>
      </div>
    </header><!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section
      id="hero"
      class="d-flex justify-cntent-center align-items-center"
    >
      <div
        id="heroCarousel"
        class="container carousel carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="5000"
      >

        <!-- Slide 1 -->
        <div class="carousel-item active">
          <div class="carousel-container">
            <h2 class="animate__animated animate__fadeInDown">
              Hệ thống quản lý vệ sinh lao động Lisa 4.0
            </h2>
            <p class="animate__animated animate__fadeInUp">
              Lisa - hệ thống quản lý vệ sinh lao động tại Việt Nam cho doanh nghiệp dùng hệ thống, tự động mở cổng trải nghiệm ngay!
            </p>
          </div>
        </div>

        <!-- Slide 2 -->
        <div class="carousel-item">
          <div class="carousel-container">
            <h2 class="animate__animated animate__fadeInDown">
              Hệ thống quản lý vệ sinh lao động Lisa 4.0
            </h2>
            <p class="animate__animated animate__fadeInUp">
              Lisa - hệ thống quản lý vệ sinh lao động tại Việt Nam cho doanh nghiệp dùng hệ thống, tự động mở cổng trải nghiệm ngay!
            </p>
          </div>
        </div>

        <a
          class="carousel-control-prev"
          href="#heroCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <i class="bi bi-chevron-left carousel-control-prev-icon"></i>
        </a>

        <a
          class="carousel-control-next"
          href="#heroCarousel"
          role="button"
          data-bs-slide="next"
        >
          <!-- <span
            class="carousel-control-next-icon bx bx-chevron-right"
            aria-hidden="true"
          ></span> -->
          <i class="bi bi-chevron-right carousel-control-next-icon"></i>
        </a>

      </div>
    </section><!-- End Hero -->

    <main id="main">

      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div class="container">
          <div class="title-box">
            <h1>Doanh nghiệp</h1>
            <h3>Thông tin về những doanh nghiệp trên hệ thống quản lý an toàn
              vệ sinh lao động</h3>
          </div>
          <div class="row box-text">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_01.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>CÔNG TY TNHH THIẾT BỊ ĐIỆN MÁY HP THÁI NGUYÊN</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Xóm Yên Phú,, Xã Yên Ninh, Huyện Phú Lương, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_02.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>CÔNG TY TNHH ĐẦU TƯ PHÁT TRIỂN THƯƠNG MẠI DỊCH VỤ 375</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Tổ 4,, Phường Tân Lập, Thành phố Thái Nguyên, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_03.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>CÔNG TY CỔ PHẦN AROHA PHARMA</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Xóm Ngọc Sơn, Xã Điềm Thụy, Huyện Phú Bình, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_04.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>VĂN PHÒNG ĐẠI DIỆN CAPITAL GARMENT CO. INC TẠI THÁI NGUYÊN</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Số nhà 119/1 đường Cách Mạng Tháng Tám,, Phường Cam Giá, Thành phố Thái Nguyên, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_05.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>CÔNG TY TNHH HOÀNG NGỌC THÁI NGUYÊN</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Tổ dân phố 12,, Phường Thắng Lợi, Thành phố Sông Công, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <div class="member-img">
                  <img
                    src="assets/img/enterprise/img_06.png"
                    class="img-fluid"
                    alt=""
                  >
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>CÔNG TY TNHH CÂU XANH VÀ MÔI TRƯỜNG KHẢI HÒA</h4>
                  <span>Doanh nghiệp tư nhân</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      Xóm 7,, Xã Sơn Cẩm, Thành phố Thái Nguyên, Thái Nguyên
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      0902558259
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-seemore">
            <button
              type="button"
              class="btn btn-warning"
            >
              Xem tất cả
            </button>
          </div>
        </div>
      </section><!-- End Team Section -->

      <!-- ======= Danh sách kiểm định viên ======= -->
      <section class="services">
        <div class="container">
          <div class="title-box">
            <h1>Danh sách kiểm định viên</h1>
            <h3>Thông tin về kiểm định viên trên hệ thống quản lý an toàn
              vệ sinh lao động</h3>
          </div>
          <div class="row">
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div class="icon-box icon-box-pink">
                <div>
                  <img
                    src="@/assets/images/logo/avt4.png"
                    class="avt-style"
                  >
                </div>

                <h4 class="title">
                  <a href="">Lê Cẩm Tú</a>
                </h4>
                <p class="description">
                  Công ty TNHH Lê Minh Quang Thái Nguyên
                </p>
                <div class="icon">
                  <i class="bi bi-bar-chart-line-fill"></i>
                </div>
                <h2>4601581691</h2>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="icon-box icon-box-cyan">
                <div>
                  <img
                    src="@/assets/images/logo/avt3.png"
                    class="avt-style"
                  >
                </div>

                <h4 class="title">
                  <a href="">Hồ Trọng Tuấn</a>
                </h4>
                <p class="description">
                  Công ty cổ phần phát triển nông nghiệp công nghệ cao Phú Thái
                </p>
                <div class="icon">
                  <i class="bi bi-bag-dash-fill"></i>
                </div>
                <h2>234500453</h2>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="icon-box icon-box-green">
                <div>
                  <img
                    src="@/assets/images/logo/avt2.png"
                    class="avt-style"
                  >
                </div>

                <h4 class="title">
                  <a href="">Trần Thế Dương</a>
                </h4>
                <p class="description">
                  Công ty TNHH xây lắp điện và viễn thông Thái Nguyên
                </p>
                <div class="icon">
                  <i class="bi bi-book-half"></i>
                </div>
                <h2>76785400453</h2>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="icon-box icon-box-blue">
                <div>
                  <img
                    src="@/assets/images/logo/avt1.png"
                    class="avt-style"
                  >
                </div>
                <h4 class="title">
                  <a href="">Vũ Hùng Vĩ</a>
                </h4>
                <p class="description">
                  Công ty TNHH Hoàng Đạt Thái Nguyên
                </p>
                <div class="icon">
                  <i class="bi bi-briefcase-fill"></i>
                </div>
                <h2>3457700453</h2>
              </div>
            </div>

          </div>
          <div class="btn-seemore">
            <button
              type="button"
              class="btn btn-warning"
            >
              Xem tất cả
            </button>
          </div>
        </div>
      </section><!-- End Services Section -->

      <!-- ======= Why Us Section ======= -->
      <section
        class="why-us section-bg"
        data-aos="fade-up"
        date-aos-delay="200"
      >
        <div class="title-box">
          <h1>Tin tức</h1>
        </div>
        <div class="container">

          <div class="row mr-0 ml-0 bg-white">
            <div class="col-lg-4 p-0">
              <img
                src="assets/img/enterprise/img_07.png"
                class="img-fluid-new"
                alt=""
              >
            </div>

            <div class="col-lg-8 d-flex flex-column p-5">
              <h1>Thái Nguyên sớm hiện thực hóa xây dựng Khu công nghiệp Sông Công II - Giai đoạn 2</h1>
              <div class="row mb-2 mt-1">
                <div class="col-3 d-flex">
                  <i class="bi bi-person mr-2"></i>
                  <div>
                    Đức Năm
                  </div>
                </div>

                <div class="col-3 d-flex">
                  <i class="bi bi-calendar-event mr-2"></i>
                  <div>
                    11-08-2021 11:26
                  </div>
                </div>
              </div>
              <p>Thủ tướng Chính phủ đã có Công văn số 132/TTg-CN ngày 29/01/2021, về việc điều chỉnh, bổ sung quy hoạch phát triển các khu công nghiệp trên địa bàn tỉnh Thái Nguyên, trong đó bổ sung Khu công nghiệp Sông Công II - Giai đoạn 2 với diện tích 300 ha. Việc lập Quy hoạch phân khu xây dựng tỷ lệ 1/2000 nhằm cụ thể hóa Đề án điều chỉnh, bổ sung quy hoạch phát triển các khu công nghiệp trên địa bàn tỉnh Thái Nguyên và Quy hoạch chung thành phố Sông Công đến năm 2040, đảm bảo đáp ứng lâu dài cho nhu cầu hoạt động của các cơ sở sản xuất, dịch vụ, các khu dân cư và đô thị.....</p>
            </div>
          </div>

        </div>
        <div class="btn-seemore">
          <button
            type="button"
            class="btn btn-warning"
          >
            Xem tất cả
          </button>
        </div>
      </section><!-- End Why Us Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 footer-links">
              <h4>Sở Lao Động - Thương Binh và Xã Hội Tỉnh Thái Nguyên</h4>
              <div class="mb-1">
                <i class="bi bi-geo-alt mr-1"></i> Số 2A, đường Phủ Liễn, phường Hoàng Văn Thụ, thành phố Thái Nguyên, tỉnh Thái Nguyên
              </div>
              <div class="mb-1">
                <i class="bi bi-telephone mr-1"></i> 0280 3 854 911
              </div>
              <div><i class="bi bi-envelope mr-1"></i> soldtbxh@thainguyen.gov.vn</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="container mt-1">
        <img src="@/assets/images/logo/logo-footer-bottom.png">
      </div> -->
    </footer><!-- End Footer -->

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i></a>
  </div>
</template>
<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      tokenCheck: localStorage.getItem('token'),
    }
  },
  created() {
    console.log(this.$route.params.check)
    if (this.$route.params.check === true) {
      this.reload()
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },
    reload() {
      setTimeout(this.$router.go('/home'), 1000)
    },
  },
}
</script>
<style>
  @import './assets/vendor/aos/aos.css';
  @import './assets/css/style.css';
  @import './assets/vendor/animate.css/animate.min.css';
  @import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
</style>
